html {
  height: 100%;
}
body {
  margin: 0;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
  min-height: 600px;
  width:100%;
  background: linear-gradient(140deg, #3a3e4b, #15171e);
  padding:0 0 10px 0;
}
.downloadbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#brandlogo {
  background: url('../UI/images/nestxlogo.png') no-repeat center center;
  background-size: contain;
  width: 289px;
  height: 66px;
  margin: 30px 0;
  min-height: 66px;
}
.socials {
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap:20px;
}
#social
{
  width: 24px;
  height: 24px;
  opacity: .3;
  padding:15px 0;
}
.twitter {
  background: url('../UI/images/twitter.png') no-repeat center center;
  background-size: contain;
}
.discord {
  background: url('../UI/images/discord.png') no-repeat center center;
  background-size: contain;
}
.gen-text {
  font-size: 35px;
  background: linear-gradient(45deg, #0074e0, #ffbe8a, #2dfdb5); 
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.gen-text-v {
  color: #ffffff91;
  font-size: 12px;
}
.download {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  justify-content: center; /* Center buttons when wrapped */
  gap: 20px; /* Spacing between buttons */
  margin: 30px 0;
}

.download a {
  background: #0074e0;
  color: #fff;
  height: 60px;
  font-size: 25px;
  text-decoration: none;
  border-radius: 3px;
  padding: 0 20px; /* Instead of width to make it flexible */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}
.download-link{
  color: #6085ff;
  text-decoration: none;
  cursor:pointer;
}
#windowslogo {
  background: url('../UI/images/windows.png') no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-right: 10px; /* Align next to text */
}
.launcherbox {
  display: flex;
  justify-content: center; /* Center launcher image */
  align-items: flex-start;
}

.launcherbox .launcher {
  background: url('../UI/images/Launcher.png') no-repeat center top;
  background-size:contain;
  width: 100%; /* Make width responsive */
  height: 607px; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .content {
    min-height: auto;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%; /* Increase width for small screens */
  }

  #brandlogo {
    width: 200px; /* Smaller logo on mobile */
    height: auto; /* Maintain aspect ratio */
  }

  .gen-text {
    font-size: 16px; /* Smaller text on mobile */
  }

  .gen-text-v {
    font-size: 10px; /* Smaller version text on mobile */
  }


}

